const { EventEmitter } = require("events");
const { v4: uuidv4 } = require("uuid");

function calculateDistance(x1, y1, x2, y2) {
    const p1 = { x: x1, y: y1 };
    const p2 = { x: x2, y: y2 };
    const X = p2.x - p1.x;
    const Y = p2.y - p1.y;
    return Math.sqrt(X * X + Y * Y);
}

exports.Entity = class extends EventEmitter {
    type = "entity";
    isEntity = true;
    x = 0;
    y = 0;
    speedX = 0;
    speedY = 0;
    velocityX = 0;
    velocityY = 0;
    uuid = uuidv4();
    world = null;
    getDistanceFromPos(x, y) {
        return calculateDistance(this.x, this.y, x, y);
    }
    getDistanceFrom(entity) {
        return this.getDistanceFromPos(entity.x, entity.y);
    }
    setPos(x, y) {
        if (this.x !== x || this.y !== y) {
            this.x = x;
            this.y = y;
            this.emit("positionChanged");
        }
    }
    getPos() {
        return { x: this.x, y: this.y }
    }
    setSpeed(x, y) {
        if (this.speedX !== x || this.speedY !== y) {
            this.speedX = x;
            this.speedY = y;
            this.emit("speedChanged");
        }
    }
    getSpeed() {
        return { x: this.speedX, y: this.speedY }
    }
    setVelocity(x, y) {
        if (this.velocityX !== x || this.velocityY !== y) {
            this.velocityX = x;
            this.velocityY = y;
            this.emit("velocityChanged");
        }
    }
    getVelocity() {
        return { x: this.velocityX, y: this.velocityY }
    }
    delete() {
        if (this.world !== null) {
            this.world.removeEntity(this);
        }
    }
    onTick(delta) {
        this.emit("tick", delta);
        if (this.velocityX !== 0 || this.velocityY !== 0) {
            this.setSpeed(this.speedX + this.velocityX * delta, this.speedY + this.velocityY * delta);
        }
        if (this.speedX !== 0 || this.speedY !== 0) {
            this.setPos(this.x + this.speedX * delta, this.y + this.speedY * delta);
        }
    }
    getData() {
        const data = {};
        data.x = this.x;
        data.y = this.y;
        data.speedX = this.speedX;
        data.speedY = this.speedY;
        data.velocityX = this.velocityX;
        data.velocityY = this.velocityY;
        data.uuid = this.uuid;
        data.type = this.type;
        return data;
    }
    setDataElementIfExists(data, key) {
        if (key === "position") {
            if (data.x !== undefined && data.y !== undefined) {
                this.setPos(data.x, data.y);
            }
        } else if (key === "speed") {
            if (data.speedX !== undefined && data.speedY !== undefined) {
                this.setSpeed(data.speedX, data.speedY);
            }
        } else if (key === "velocity") {
            if (data.velocityX !== undefined && data.velocityY !== undefined) {
                this.setVelocity(data.velocityX, data.velocityY);
            }
        } else if (data[key] !== undefined) {
            this[key] = data[key];
        }
    }
    setData(data) {
        this.setDataElementIfExists(data, "position");
        this.setDataElementIfExists(data, "speed");
        this.setDataElementIfExists(data, "velocity");
        this.setDataElementIfExists(data, "uuid");
        //this.setDataElementIfExists(data, "type");
    }
    static fromData(data) {
        const entity = new this();
        entity.setData(data);
        return entity;
    }
}

exports.Blob = class extends exports.Entity {
    type = "blob";
    isBlob = true;
    _size = 10; // = radius
    maxSize = -1;
    minSize = 10;
    injuryMultiplier = 1;
    damageMultiplier = 5;
    healStealMultiplier = 0.5;
    control = false;
    controlDirection = 0;
    controlMaxSpeed = 75;
    _color = 0x3399ff;
    _displayName = null;
    controlX = 0;
    controlY = 0;
    get color() {
        return this._color;
    }
    set color(c) {
        if (c !== this._color) {
            this._color = c;
            this.emit("colorChanged", c);
        }
    }
    get displayName() {
        return this._displayName;
    }
    set displayName(v) {
        if (v !== this._displayName) {
            this._displayName = v;
            this.emit("displayNameChanged", v);
        }
    }
    setRelativeControlTarget(relativeX, relativeY) {
        /*this.control = true;
        let direction = Math.atan2(relativeX, relativeY);
        let distance = calculateDistance(0, 0, relativeX, relativeY);
        let speed = Math.min(1, distance / this.size) * this.controlMaxSpeed;
        speed *= (10 / this.size) / 2 + 0.5;
        this.speedX = Math.sin(direction) * speed;
        this.speedY = Math.cos(direction) * speed;*/
        if (this.controlX !== relativeX || this.controlY !== relativeY) {
            this.control = true;
            this.controlX = relativeX;
            this.controlY = relativeY;
        }
    }
    controlTicker(delta = 1) {
        if (this.control) {
            let relativeX = this.controlX;
            let relativeY = this.controlY;
            let direction = Math.atan2(relativeX, relativeY);
            let distance = calculateDistance(0, 0, relativeX, relativeY);
            let speed = Math.min(1, distance / this.size) * this.controlMaxSpeed;
            speed *= (10 / this.size) / 2 + 0.5;
            let targetSpeedX = Math.sin(direction) * speed;
            let targetSpeedY = Math.cos(direction) * speed;

            let speedD = 0.075;
            let speedTolerance = 0.05;
            if (!(Math.abs(this.speedX) > Math.abs(targetSpeedX) - speedTolerance &&
                Math.abs(this.speedX) < Math.abs(targetSpeedX) + speedTolerance &&
                Math.abs(this.speedY) > Math.abs(targetSpeedY) - speedTolerance &&
                Math.abs(this.speedY) < Math.abs(targetSpeedY) + speedTolerance)) {
                this.speedX += (targetSpeedX - this.speedX) / speedD * delta;
                this.speedY += (targetSpeedY - this.speedY) / speedD * delta;
            } else {
                this.speedX = targetSpeedX;
                this.speedY = targetSpeedY;
            }
        }
    }
    get size() {
        return this._size;
    }
    set size(s) {
        if (this._size !== s) {
            this._size = s;
            this.emit("sizeChanged", s);
        }
    }
    getData() {
        const data = super.getData();
        data.minSize = this.minSize;
        data.maxSize = this.maxSize;
        data.size = this.size;
        data.injuryMultiplier = this.injuryMultiplier;
        data.damageMultiplier = this.damageMultiplier;
        data.healStealMultiplier = this.healStealMultiplier;
        data.controlMaxSpeed = this.controlMaxSpeed;
        data.color = this.color;
        data.displayName = this.displayName;
        data.control = this.control;
        if (this.control) {
            data.controlX = this.controlX;
            data.controlY = this.controlY;
        }
        return data;
    }
    setData(data) {
        super.setData(data);
        this.setDataElementIfExists(data, "minSize");
        this.setDataElementIfExists(data, "maxSize");
        this.setDataElementIfExists(data, "size");
        this.setDataElementIfExists(data, "injuryMultiplier");
        this.setDataElementIfExists(data, "damageMultiplier");
        this.setDataElementIfExists(data, "healStealMultiplier");
        this.setDataElementIfExists(data, "controlMaxSpeed");
        this.setDataElementIfExists(data, "color");
        this.setDataElementIfExists(data, "displayName");
        this.setDataElementIfExists(data, "control");
        this.setDataElementIfExists(data, "controlX");
        this.setDataElementIfExists(data, "controlY");
    }
    kill(killedBy = null) {
        this.delete();
        this.emit("kill", killedBy);
    }
    onTick(delta) {
        super.onTick(delta);

        if (this.maxSize >= this.minSize && this.size > this.maxSize) {
            this.size = this.maxSize;
        }

        this.controlTicker(delta);

        if (this.world !== null && this.world.isWorld) {
            for (let i = 0; i < this.world.entities.length; i++) {
                const entity = this.world.entities[i];
                if (entity.isBlob === true && entity.uuid !== this.uuid) {
                    let d = this.getDistanceFrom(entity) - this.size - entity.size;
                    //if(this === this.world.entities[0] && entity === this.world.entities[1]) console.log(d);
                    if (d <= 0) {
                        if (entity.damageMultiplier !== 0 && this.injuryMultiplier !== 0) {
                            let myInjury = delta * entity.damageMultiplier * this.injuryMultiplier;
                            if (this.size < entity.size) {
                                this.size -= myInjury;
                                entity.size += myInjury * entity.healStealMultiplier;
                                this.emit("damage", entity);
                                entity.emit("attack", this);
                            }
                        }

                        if (this.size >= entity.size && d <= -1) {
                            let relX = entity.x - this.x;
                            let relY = entity.y - this.y;
                            let direction = Math.atan2(relX, relY);
                            let newX = Math.sin(direction) * (this.size + entity.size - 1);
                            let newY = Math.cos(direction) * (this.size + entity.size - 1);
                            entity.setPos(this.x + newX, this.y + newY);
                        }
                        this.emit("touch", entity);
                    }
                }
            }
            if (this.getDistanceFromPos(0, 0) + this.size > this.world.size) {
                let myInjury = delta * this.injuryMultiplier * this.world.damageMultiplier;
                this.size -= myInjury;
                this.emit("damage", this.world);
            }
        }

        if (this.size < this.minSize) {
            this.kill();
        } else {
            let decreasePercentagePerSecond = 0.0001; // 0.0001 = 0.01%
            let newSize = this.size - this.size * decreasePercentagePerSecond * delta;
            this.size = Math.max(this.minSize, newSize);
        }
    }
}

exports.World = class extends exports.Entity {
    type = "world";
    isWorld = true;
    entities = [];
    _size = 500;
    damageMultiplier = 2;
    get size() {
        return this._size;
    }
    set size(s) {
        if (this._size !== s) {
            this._size = s;
            this.emit("sizeChanged", s);
        }
    }
    getRandomCoordinate() {
        const padding = this.size * 0.25;
        const maxDistance = this.size - padding;
        return Math.random() * maxDistance * 2 - maxDistance;
    }
    getRandomCoordinates() {
        return { x: this.getRandomCoordinate(), y: this.getRandomCoordinate() };
    }
    addEntity(entity) {
        if (this.entities.indexOf(entity) === -1) {
            entity.world = this;
            this.entities.push(entity);
            this.emit("entityAdded", entity);
        }
    }
    removeEntity(entity) {
        const i = this.entities.indexOf(entity);
        entity.world = null;
        if (i > -1) {
            this.entities.splice(i, 1);
            this.emit("entityRemoved", entity);
            entity.emit("deleted");
            return true;
        } else {
            return false;
        }
    }
    getEntityByUUID(uuid) {
        for (let i = 0; i < this.entities.length; i++) {
            const entity = this.entities[i];
            if (entity.uuid === uuid) {
                return entity;
            }
        }
        return null;
    }
    onTick(delta) {
        const self = this;
        this.entities.forEach(entity => {
            entity.onTick(delta);
            if (entity.size > self.size / 4) {
                entity.size = self.size / 4;
            }
        });
        this.emit("tick", delta);
    }
    getData() {
        const data = super.getData();
        const entities = [];
        this.entities.forEach(entity => {
            entities.push(entity.getData());
        });
        data.entities = entities;
        data.size = this.size;
        return data;
    }
    setData(data) {
        super.setData(data);
        const newEntities = [];
        const self = this;
        if (data.entities !== undefined) {
            const entities = this.entities;
            data.entities.forEach(entityData => {
                let newEntity = true;
                for (let i = 0; i < entities.length; i++) {
                    const entity = entities[i];
                    if (entity.uuid === entityData.uuid) {
                        entity.setData(entityData);
                        newEntity = false;
                        break;
                    }
                }
                if (newEntity) {
                    self.addEntityByData(entityData);
                }
            });
        }
        this.setDataElementIfExists(data, "size");
    }

    setDataSoft(data) {
        const self = this;
        if (data.entities !== undefined) {
            const entities = this.entities;
            data.entities.forEach(entityData => {
                let newEntity = true;
                for (let i = 0; i < entities.length; i++) {
                    const entity = entities[i];
                    if (entity && entity.uuid === entityData.uuid) {
                        const diffX = entity.x - entityData.x;
                        const diffY = entity.y - entityData.y;
                        const maxDiff = Math.max(Math.abs(diffX), Math.abs(diffY));
                        let correctionThreshold = 1;
                        let teleportAt = 20;
                        let correctionStrength = 0.1;
                        if (maxDiff > teleportAt) {
                            entity.x = entityData.x;
                            entity.y = entityData.y;
                        } else if (maxDiff > correctionThreshold) {
                            entityData.x = entity.x - diffX * correctionStrength;
                            entityData.y = entity.y - diffY * correctionStrength;
                        } else {
                            entityData.x = entity.x;
                            entityData.y = entity.y;
                        }
                        entity.setData(entityData);
                        newEntity = false;
                        break;
                    }
                }
                if (newEntity) {
                    self.addEntityByData(entityData);
                }
            });
        }

        data.entities = undefined;

        this.setData(data);
    }

    addEntityByData(data) {
        if (typeof (data.type) === "string") {
            switch (data.type) {
                case "blob":
                    const blob = exports.Blob.fromData(data);
                    this.addEntity(blob);
                    return blob;
                case "entity":
                    const entity = exports.Entity.fromData(data);
                    this.addEntity(entity);
                    return entity;
                default:
                    break;
            }
        }
        return null;
    }

    clear() {
        for (let i = this.entities.length - 1; i >= 0; i--) {
            const entity = this.entities[i];
            entity.delete();
        }
    }

    removeEntityByUUID(uuid) {
        const entity = this.getEntityByUUID(uuid);
        if (entity !== null) {
            entity.delete();
        }
    }
}