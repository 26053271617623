import EventEmitter from "events";
import * as Game from "../../shared/game";

export class GameWorker extends EventEmitter {
    socket = null;
    myBlob = null;
    clientUUID = null;
    roomUUID = null;
    getMyBlob() {
        if (this.myBlob !== null) {
            return this.world.getEntityByUUID(this.myBlob);
        }
        return null;
    }
    constructor(socket) {
        super();
        const self = this;
        this.socket = socket;

        const world = this.world = new Game.World();

        this.reset();
    }
    reset() {
        this.myBlob = null;
        this.clientUUID = null;
        this.roomUUID = null;
        this.world.clear();
        if (!this.socket) {
            return;
        }

        this.registerListeners();
    }
    registerListeners(socket = this.socket) {
        this.socket = socket;
        const world = this.world;
        const self = this;
        socket.on("worldData", data => {
            //console.log("worldData");
            world.setDataSoft(data);
        });

        socket.on("entityData", data => {
            const entity = world.getEntityByUUID(data.uuid);
            if (entity !== null) {
                entity.setDataSoft(data);
            } else {
                world.addEntityByData(data);
            }
        });

        /*socket.on("setControlledBlob", uuid => {
            const entity = world.getEntityByUUID(uuid);
            if (entity !== null) {
                self.myBlob = entity;
            }
        });*/

        socket.on("resetWorld", () => {
            self.world.clear();
            self.myBlob = null;
        })

        socket.on("clientData", data => {
            self.myBlob = data.controlledEntity;
            self.clientUUID = data.uuid;
            self.roomUUID = data.room;
        });

        socket.on("entityRemoved", uuid => {
            console.debug("entityRemoved event, uuid:", uuid);
            self.world.removeEntityByUUID(uuid);
        })

        socket.on("died", () => {
            self.emit("died");
        });

        socket.on("pong", data => {
            self.emit("pong", data);
        });
    }
}