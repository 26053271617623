import * as PIXI from "pixi.js";

let debugMode = false;

window.turnOnDebug = function () {
    debugMode = true;
}

window.turnOffDebug = function () {
    debugMode = false;
}

window.toggleDebug = function () {
    if (debugMode) {
        turnOffDebug();
    } else {
        turnOnDebug();
    }
}

const injectors = [];
function addInjector(injector) {
    console.debug("Injector created:", injector);
    if (injectors.indexOf(injector) === -1) {
        injectors.push(injector);
        injector.entity.on("delete", () => {
            let i = injectors.indexOf(injector);
            if (i !== -1) {
                injectors.splice(i, 1);
            }
        });
    }
}

export class Animator {
    constructor(time = 1, fps = 60) {
        this.fps = fps;
        this.time = time;
    }

    onStep(delta, frame, elapsedTime) { }

    onDone() { }

    step(delta, frame, elapsedTime) {
        this.onStep(delta, frame, elapsedTime);
    }

    stop() { }

    play() {
        const self = this;
        let frame = 0;
        let elapsedTime = 0;
        let t = new Date().getTime();
        function step() {
            let t2 = new Date().getTime();
            let delta = (t2 - t) / 1000;
            t = t2;
            self.step(delta, frame, elapsedTime);
            elapsedTime += delta;
            frame++;
            if (elapsedTime < self.time) {
                setTimeout(step, 1000 / self.fps);
            } else {
                self.onDone();
            }
        }
        step();
        this.stop = () => {
            elapsedTime = self.time * 2;
        }
    }
}

export class BasicInjector {
    pixiObj = new PIXI.Container();
    entity = null;
    constructor(entity) {
        this.pixiObj.sortableChildren = true;
        this.entity = entity;
        entity.pixiObj = this.pixiObj;

        const self = this;

        const debugObj = this.debugObj = new PIXI.Container();
        this.pixiObj.addChild(debugObj);
        debugObj.visible = debugMode;
        debugObj.zIndex = 800;

        const posCircle = new PIXI.Graphics();
        posCircle.beginFill(0xe74c3c);
        posCircle.drawCircle(0, 0, 5);
        posCircle.endFill();
        debugObj.addChild(posCircle);

        const speedLine = new PIXI.Graphics();
        speedLine.name = "speedLine";
        debugObj.addChild(speedLine);

        this.entity.on("tick", delta => {
            self.pixiObj.position.set(self.entity.x, self.entity.y);
            if (debugMode) {
                if (!debugObj.visible) debugObj.visible = true;
                speedLine.clear();
                speedLine.lineStyle(2, 0x3333ff)
                    .moveTo(0, 0)
                    .lineTo(self.entity.speedX, self.entity.speedY)
            } else {
                if (debugObj.visible) debugObj.visible = false;
            }
        })

        addInjector(this);
    }
}

export class GraphicalEntity extends BasicInjector {
    constructor(entity) {
        super(entity);
        console.debug("ClientInjector -> Entity injection...", entity.uuid);
        const self = this;
        entity.on("deleted", () => {
            console.debug("Entity deleted, destroying PIXI object...");
            self.pixiObj.destroy();
        });
    }
}

export class GraphicalBlob extends GraphicalEntity {
    size = 10;
    debugDamageTimer = 0;
    debugDamageObj = null;
    setSize(s) {
        s = Math.round(s * 10) / 10;
        if (s !== this.size) {
            this.size = s;
            this.refresh();
        }
    }
    refresh() {
        this.pixiObj.zIndex = 1 + 1 * (this.size / 100);

        const sizeDebug = this.debugObj.getChildByName("sizeDebug");
        sizeDebug.clear();
        sizeDebug.lineStyle(1, 0x00ff00);
        sizeDebug.drawCircle(0, 0, this.size);

        const mainCircle = this.pixiObj.getChildByName("mainCircle");
        mainCircle.clear();
        mainCircle.beginFill(this.entity.color);
        mainCircle.drawCircle(0, 0, this.size);
        mainCircle.endFill();

        const displayNameContainer = this.pixiObj.getChildByName("displayNameContainer");
        if (this.entity.displayName !== null) {
            const displayName = displayNameContainer.getChildByName("displayName");
            const displayNameBackground = displayNameContainer.getChildByName("displayNameBackground");
            if (!displayNameContainer.visible) displayNameContainer.visible = true;
            displayName.text = this.entity.displayName;
            displayNameContainer.scale.set(Math.min(this.entity.size / 100, 1));
            let minX = -displayName.width / 2;
            let padding = 10 * displayNameContainer.scale.x;
            minX -= padding / 2;
            let dpbgWidth = Math.abs(minX * 2);
            let dpbgHeight = displayName.height + padding;
            displayNameBackground.clear();
            displayNameBackground.alpha = 0.75;
            displayNameBackground.beginFill(0x333333);
            displayNameBackground.drawRect(minX, -dpbgHeight / 2, dpbgWidth, dpbgHeight);
            displayNameBackground.endFill();
        } else {
            if (displayNameContainer.visible) displayNameContainer.visible = false;
        }
    }
    constructor(entity) {
        super(entity);
        const self = this;

        const spawnAnimator = new Animator(0.3, 60);
        spawnAnimator.onStep = (delta, frame, time) => {
            try {
                self.pixiObj.scale.set(time / 0.3);
                self.pixiObj.alpha = time / 0.3;
            } catch (e) {
                spawnAnimator.stop();
            }
        }
        spawnAnimator.onDone = () => {
            try {
                if (self.pixiObj) self.pixiObj.alpha = 1;
                self.pixiObj.scale.set(1);
            } catch (e) { }
        }
        spawnAnimator.play();

        self.pixiObj.sortableChildren = true;

        const sizeDebug = new PIXI.Graphics();
        sizeDebug.name = "sizeDebug";
        this.debugObj.addChild(sizeDebug);

        const mainCircle = new PIXI.Graphics();
        mainCircle.name = "mainCircle";
        this.pixiObj.addChild(mainCircle);

        const attackDebug = new PIXI.Graphics();
        attackDebug.name = "attackDebug";
        this.debugObj.addChild(attackDebug);

        const displayNameContainer = new PIXI.Container();
        displayNameContainer.name = "displayNameContainer";
        const displayName = new PIXI.Text();
        displayName.name = "displayName";
        displayName.style.fill = 0xffffff;
        displayName.anchor.set(0.5);
        displayName.style.fontSize = 40;
        displayNameContainer.addChild(displayName);
        const displayNameBackground = new PIXI.Graphics();
        displayNameBackground.name = "displayNameBackground";
        displayNameContainer.addChild(displayNameBackground);
        this.pixiObj.addChild(displayNameContainer);
        displayNameContainer.sortableChildren = true;
        displayNameBackground.zIndex = -1;

        this.setSize(this.entity.size);
        this.refresh();

        entity.on("damage", attacker => {
            if (attacker.isBlob) {
                self.debugDamageTimer = 0;
                self.debugDamageObj = attacker;
            }
        });

        entity.on("tick", delta => {
            if (debugMode) {
                if (self.debugDamageTimer <= 0.2 && this.debugDamageObj !== null && this.debugDamageObj.world !== null) {
                    self.debugDamageTimer += delta;
                    attackDebug.visible = true;
                    attackDebug.clear();
                    attackDebug.lineStyle(2, 0xff3333)
                        .moveTo(0, 0)
                        .lineTo(-(entity.x - self.debugDamageObj.x), -(entity.y - self.debugDamageObj.y))
                } else {
                    attackDebug.visible = false;
                }
            }
        });

        entity.on("sizeChanged", s => {
            self.setSize(s);
        });

        entity.on("colorChanged", () => {
            self.refresh();
        });

        entity.on("displayNameChanged", displayName => {
            self.refresh();
        });

        let prevDamage = new Date().getTime();
        let particlesPerSecond = 20;

        let particleSize = 3;
        let particleTravelMaxDiff = entity.size / 3;

        entity.on("attack", victim => {
            let t = new Date().getTime();
            let elapsed = t - prevDamage;
            if (elapsed >= 1000 / particlesPerSecond) {
                prevDamage = t;
                let direction = Math.atan2(victim.x - entity.x, victim.y - entity.y);
                let x = Math.sin(direction) * entity.size;
                let y = Math.cos(direction) * entity.size;

                let randomDirection = Math.random() * Math.PI * 2;
                let pX = Math.sin(randomDirection);
                let pY = Math.cos(randomDirection);
                let travelDistance = (entity.size * 2 + victim.size * 2) / 2 - Math.random() * particleTravelMaxDiff * 2 - particleTravelMaxDiff;

                let particle = new PIXI.Graphics();
                particle.lineStyle(2, 0xffffff);
                particle.moveTo(0, 0);
                particle.lineTo(pX * particleSize, pY * particleSize);

                particle.position.set(x, y);
                particle.zIndex = 20;

                let steps = 2;
                let framesCount = travelDistance / steps;

                let stepCounter = 0;
                let animationInterval = setInterval(() => {
                    let aX = Math.sin(randomDirection) * steps;
                    let aY = Math.cos(randomDirection) * steps;
                    particle.position.set(particle.position.x + aX, particle.position.y + aY);
                    stepCounter++;
                    if (stepCounter >= framesCount) {
                        self.pixiObj.removeChild(particle);
                        clearInterval(animationInterval);
                    }
                }, 1000 / 60);

                self.pixiObj.addChild(particle);
            }
        });
    }
}

export class GraphicalWorld extends BasicInjector {
    size = 50;
    setSize(s) {
        s = Math.round(s * 10) / 10;
        if (s !== this.size) {
            this.size = s;
            this.refresh();
        }
    }
    refresh() {
        const border = this.pixiObj.getChildByName("border");
        border.clear();
        border.lineStyle(3, 0xff4444);
        border.drawCircle(0, 0, this.size);

        const floor = this.pixiObj.getChildByName("floor");
        floor.clear();
        floor.beginFill(0x222222);
        floor.drawCircle(0, 0, this.size);
        floor.endFill();
        floor.beginFill(0x111111);
        floor.lineStyle(3, 0x050505);
        let a = (this.entity.size % 30) - 30;
        for (let x = -this.entity.size + a; x <= this.entity.size; x += 30) {
            for (let y = -this.entity.size + a; y <= this.entity.size; y += 30) {
                floor.drawRect(x + 10, y + 10, 20, 20);
            }
        }
        //floor.drawRect(0, 0, 20, 20);
        floor.endFill();

        const mask = this.pixiObj.mask;
        mask.clear();
        mask.beginFill(0x000000);
        mask.drawCircle(0, 0, this.size);
        mask.endFill();
    }
    constructor(world) {
        super(world);
        console.debug("ClientInjector -> World injection...", world.uuid);
        const self = this;
        self.pixiObj.sortableChildren = true;

        const border = new PIXI.Graphics();
        border.name = "border";
        border.zIndex = 1;
        this.pixiObj.addChild(border);

        const floor = new PIXI.Graphics();
        floor.name = "floor";
        this.pixiObj.addChild(floor);

        const mask = new PIXI.Graphics();
        this.pixiObj.mask = mask;
        this.pixiObj.addChild(mask);

        this.setSize(this.entity.size);
        this.refresh();

        world.on("entityAdded", entity => {
            let injector;
            switch (entity.type) {
                case "blob":
                    injector = new GraphicalBlob(entity);
                    break;
                default:
                    injector = new GraphicalEntity(entity);
                    break;
            }
            self.pixiObj.addChild(injector.pixiObj);
        });

        world.on("sizeChanged", s => {
            self.setSize(s);
        });
    }
}